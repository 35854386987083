exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-poetry-tsx": () => import("./../../../src/pages/poetry.tsx" /* webpackChunkName: "component---src-pages-poetry-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-writings-tsx": () => import("./../../../src/pages/writings.tsx" /* webpackChunkName: "component---src-pages-writings-tsx" */),
  "component---src-templates-poetry-tsx-content-file-path-content-poetry-gazing-at-the-horizon-mdx": () => import("./../../../src/templates/poetry.tsx?__contentFilePath=/home/runner/work/fibration.github.io/fibration.github.io/content/poetry/gazing_at_the_horizon.mdx" /* webpackChunkName: "component---src-templates-poetry-tsx-content-file-path-content-poetry-gazing-at-the-horizon-mdx" */),
  "component---src-templates-poetry-tsx-content-file-path-content-poetry-limerick-1-mdx": () => import("./../../../src/templates/poetry.tsx?__contentFilePath=/home/runner/work/fibration.github.io/fibration.github.io/content/poetry/limerick_1.mdx" /* webpackChunkName: "component---src-templates-poetry-tsx-content-file-path-content-poetry-limerick-1-mdx" */),
  "component---src-templates-poetry-tsx-content-file-path-content-poetry-limerick-2-mdx": () => import("./../../../src/templates/poetry.tsx?__contentFilePath=/home/runner/work/fibration.github.io/fibration.github.io/content/poetry/limerick_2.mdx" /* webpackChunkName: "component---src-templates-poetry-tsx-content-file-path-content-poetry-limerick-2-mdx" */),
  "component---src-templates-poetry-tsx-content-file-path-content-poetry-lorem-ipsum-mdx": () => import("./../../../src/templates/poetry.tsx?__contentFilePath=/home/runner/work/fibration.github.io/fibration.github.io/content/poetry/lorem_ipsum.mdx" /* webpackChunkName: "component---src-templates-poetry-tsx-content-file-path-content-poetry-lorem-ipsum-mdx" */),
  "component---src-templates-poetry-tsx-content-file-path-content-poetry-post-extraction-mdx": () => import("./../../../src/templates/poetry.tsx?__contentFilePath=/home/runner/work/fibration.github.io/fibration.github.io/content/poetry/post_extraction.mdx" /* webpackChunkName: "component---src-templates-poetry-tsx-content-file-path-content-poetry-post-extraction-mdx" */),
  "component---src-templates-poetry-tsx-content-file-path-content-poetry-sonnet-1-mdx": () => import("./../../../src/templates/poetry.tsx?__contentFilePath=/home/runner/work/fibration.github.io/fibration.github.io/content/poetry/sonnet_1.mdx" /* webpackChunkName: "component---src-templates-poetry-tsx-content-file-path-content-poetry-sonnet-1-mdx" */),
  "component---src-templates-poetry-tsx-content-file-path-content-poetry-sonnet-2-mdx": () => import("./../../../src/templates/poetry.tsx?__contentFilePath=/home/runner/work/fibration.github.io/fibration.github.io/content/poetry/sonnet_2.mdx" /* webpackChunkName: "component---src-templates-poetry-tsx-content-file-path-content-poetry-sonnet-2-mdx" */),
  "component---src-templates-poetry-tsx-content-file-path-content-poetry-sonnet-3-mdx": () => import("./../../../src/templates/poetry.tsx?__contentFilePath=/home/runner/work/fibration.github.io/fibration.github.io/content/poetry/sonnet_3.mdx" /* webpackChunkName: "component---src-templates-poetry-tsx-content-file-path-content-poetry-sonnet-3-mdx" */),
  "component---src-templates-poetry-tsx-content-file-path-content-poetry-sonnet-4-mdx": () => import("./../../../src/templates/poetry.tsx?__contentFilePath=/home/runner/work/fibration.github.io/fibration.github.io/content/poetry/sonnet_4.mdx" /* webpackChunkName: "component---src-templates-poetry-tsx-content-file-path-content-poetry-sonnet-4-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-projects-neural-pulse-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/home/runner/work/fibration.github.io/fibration.github.io/content/projects/neural_pulse.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-projects-neural-pulse-mdx" */),
  "component---src-templates-writing-tsx-content-file-path-content-posts-consequentialist-blank-cheque-1-mdx": () => import("./../../../src/templates/writing.tsx?__contentFilePath=/home/runner/work/fibration.github.io/fibration.github.io/content/posts/consequentialist-blank-cheque-1.mdx" /* webpackChunkName: "component---src-templates-writing-tsx-content-file-path-content-posts-consequentialist-blank-cheque-1-mdx" */),
  "component---src-templates-writing-tsx-content-file-path-content-posts-placeholder-1-mdx": () => import("./../../../src/templates/writing.tsx?__contentFilePath=/home/runner/work/fibration.github.io/fibration.github.io/content/posts/placeholder1.mdx" /* webpackChunkName: "component---src-templates-writing-tsx-content-file-path-content-posts-placeholder-1-mdx" */),
  "component---src-templates-writing-tsx-content-file-path-content-posts-placeholder-2-mdx": () => import("./../../../src/templates/writing.tsx?__contentFilePath=/home/runner/work/fibration.github.io/fibration.github.io/content/posts/placeholder2.mdx" /* webpackChunkName: "component---src-templates-writing-tsx-content-file-path-content-posts-placeholder-2-mdx" */),
  "component---src-templates-writing-tsx-content-file-path-content-posts-tdd-for-ml-1-mdx": () => import("./../../../src/templates/writing.tsx?__contentFilePath=/home/runner/work/fibration.github.io/fibration.github.io/content/posts/tdd-for-ml-1.mdx" /* webpackChunkName: "component---src-templates-writing-tsx-content-file-path-content-posts-tdd-for-ml-1-mdx" */),
  "component---src-templates-writing-tsx-content-file-path-content-posts-webswinging-like-spiderman-mdx": () => import("./../../../src/templates/writing.tsx?__contentFilePath=/home/runner/work/fibration.github.io/fibration.github.io/content/posts/webswinging-like-spiderman.mdx" /* webpackChunkName: "component---src-templates-writing-tsx-content-file-path-content-posts-webswinging-like-spiderman-mdx" */)
}

